import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Login, Token } from '../models/login';
import { environment } from '../../environments/environment';
import { Location } from '../models/location';
import { User, UserPassword } from '../models/user';
import { Provider } from '../models/provider';
import { Contact } from '../models/contact';
import { Rack } from '../models/rack';
import { Product } from '../models/product';
import { AADocument } from '../models/document';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  //AUTH
  AUTH_Login(data: Login) {
    return this.http.post<Token>(`${environment.apiUrl}/login`, data, {
      observe: 'response',
    });
  }

  AUTH_ChangePassword(data: UserPassword) {
    return this.http.patch(`${environment.apiUrl}/password`, data, {
      observe: 'response',
    });
  }

  //USER
  USER_GetUser(userId: number) {
    return this.http.get<User>(`${environment.apiUrl}/users/${userId}`, {
      observe: 'response',
    });
  }

  USER_GetUsers(providerId?: number) {
    let params = new HttpParams();
    if (providerId) params = params.set('providerId', providerId);
    return this.http.get<User[]>(`${environment.apiUrl}/users`, {
      params,
      observe: 'response',
    });
  }

  USER_PostUser(user: User) {
    return this.http.post<User>(`${environment.apiUrl}/users`, user, {
      observe: 'response',
    });
  }

  USER_PutUser(user: User) {
    return this.http.put<User>(`${environment.apiUrl}/users/${user.id}`, user, {
      observe: 'response',
    });
  }

  USER_DeleteUser(userId: number) {
    return this.http.delete<User>(`${environment.apiUrl}/users/${userId}`, {
      observe: 'response',
    });
  }

  //LOCATIONS

  LOCATIONS_GetLocations() {
    return this.http.get<Location[]>(`${environment.apiUrl}/locations`, {
      observe: 'response',
    });
  }

  LOCATIONS_GetLocation(dbId: number) {
    return this.http.get<Location>(`${environment.apiUrl}/locations/${dbId}`, {
      observe: 'response',
    });
  }

  LOCATIONS_PostLocation(location: Location) {
    return this.http.post(`${environment.apiUrl}/locations`, location, {
      observe: 'response',
    });
  }

  LOCATIONS_PutLocation(location: Location) {
    return this.http.put(
      `${environment.apiUrl}/locations/${location.id}`,
      location,
      {
        observe: 'response',
      }
    );
  }

  LOCATIONS_DeleteLocation(dbId: number) {
    return this.http.delete(`${environment.apiUrl}/locations/${dbId}`, {
      observe: 'response',
    });
  }

  LOCATIONS_GetProducts(dbId: number) {
    return this.http.get<Product[]>(
      `${environment.apiUrl}/locations/${dbId}/products`,
      {
        observe: 'response',
      }
    );
  }

  LOCATIONS_GetProduct(dbId: number, productId: number) {
    return this.http.get<Product>(
      `${environment.apiUrl}/locations/${dbId}/products/${productId}`,
      {
        observe: 'response',
      }
    );
  }

  LOCATIONS_PostProduct(dbId: number, product: Product) {
    return this.http.post(
      `${environment.apiUrl}/locations/${dbId}/products`,
      product,
      {
        observe: 'response',
      }
    );
  }

  LOCATIONS_PatchProduct(dbId: number, product: Product) {
    return this.http.patch(
      `${environment.apiUrl}/locations/${dbId}/products/${product.id}`,
      {
        serialName: product.serialName,
        positionNumber: product.positionNumber,
        installationDate: product.installationDate,
      },
      {
        observe: 'response',
      }
    );
  }

  LOCATIONS_DeleteProduct(dbId: number, productId: number) {
    return this.http.delete(
      `${environment.apiUrl}/locations/${dbId}/products/${productId}`,
      {
        observe: 'response',
      }
    );
  }

  LOCATIONS_GetRacks(dbId: number) {
    return this.http.get<Rack[]>(
      `${environment.apiUrl}/locations/${dbId}/racks`,
      { observe: 'response' }
    );
  }

  LOCATIONS_GetRack(dbId: number, rackId: number) {
    return this.http.get<Rack>(
      `${environment.apiUrl}/locations/${dbId}/racks/${rackId}`,
      { observe: 'response' }
    );
  }

  LOCATIONS_PostRack(dbId: number, rack: Rack) {
    console.log(rack);
    return this.http.post(
      `${environment.apiUrl}/locations/${dbId}/racks/${rack.id}`,
      { serialName: rack.serialName },
      { observe: 'response' }
    );
  }

  LOCATIONS_PatchRack(dbId: number, rack: Rack) {
    console.log(rack);
    return this.http.patch(
      `${environment.apiUrl}/locations/${dbId}/racks/${rack.id}`,
      { serialName: rack.serialName },
      { observe: 'response' }
    );
  }

  LOCATIONS_PutRack(dbId: number, rack: Rack) {
    return this.http.put(
      `${environment.apiUrl}/locations/${dbId}/racks/${rack.id}`,
      rack,
      { observe: 'response' }
    );
  }

  LOCATIONS_DeleteRack(dbId: number, rackId: number) {
    return this.http.delete(
      `${environment.apiUrl}/locations/${dbId}/racks/${rackId}`,
      { observe: 'response' }
    );
  }

  //PROVIDERS

  PROVIDERS_GetProviders() {
    return this.http.get<Provider[]>(`${environment.apiUrl}/providers`, {
      observe: 'response',
    });
  }

  PROVIDERS_GetProvider(providerId: number) {
    return this.http.get<Provider>(
      `${environment.apiUrl}/providers/${providerId}`,
      {
        observe: 'response',
      }
    );
  }

  PROVIDERS_PostProvider(provider: Provider) {
    return this.http.post(`${environment.apiUrl}/providers`, provider, {
      observe: 'response',
    });
  }

  PROVIDERS_PutProvider(provider: Provider) {
    return this.http.put(
      `${environment.apiUrl}/providers/${provider.id}`,
      provider,
      {
        observe: 'response',
      }
    );
  }

  PROVIDERS_DeleteProvider(providerId: number) {
    return this.http.delete(`${environment.apiUrl}/providers/${providerId}`, {
      observe: 'response',
    });
  }

  PROVIDERS_AddUser(providerId: number, userId: number) {
    return this.http.post(
      `${environment.apiUrl}/providers/${providerId}/users/${userId}`,
      {},
      { observe: 'response' }
    );
  }

  PROVIDERS_DeleteUser(providerId: number, userId: number) {
    return this.http.delete(
      `${environment.apiUrl}/providers/${providerId}/users/${userId}`,
      { observe: 'response' }
    );
  }

  //CONTACTS

  CONTACTS_GetContact(contactId: number) {
    return this.http.get<Contact>(
      `${environment.apiUrl}/contacts/${contactId}`,
      {
        observe: 'response',
      }
    );
  }

  CONTACTS_GetContacts() {
    return this.http.get<Contact[]>(`${environment.apiUrl}/contacts`, {
      observe: 'response',
    });
  }

  CONTACTS_PostContact(contact: Contact) {
    return this.http.post<Contact>(`${environment.apiUrl}/contacts`, contact, {
      observe: 'response',
    });
  }

  CONTACTS_PutContact(contact: Contact) {
    return this.http.put<Contact>(
      `${environment.apiUrl}/contacts/${contact.id}`,
      contact,
      {
        observe: 'response',
      }
    );
  }

  CONTACTS_DeleteContact(contactId: number) {
    return this.http.delete(`${environment.apiUrl}/contacts/${contactId}`, {
      observe: 'response',
    });
  }

  //RACK TEMPLATES

  RACKS_GetRackTemplates() {
    return this.http.get<Rack[]>(`${environment.apiUrl}/rackTemplates`, {
      observe: 'response',
    });
  }

  RACKS_GetRackTemplate(rackId: number) {
    return this.http.get<Rack>(
      `${environment.apiUrl}/rackTemplates/${rackId}`,
      {
        observe: 'response',
      }
    );
  }

  RACKS_PostRackTemplate(rack: Rack) {
    return this.http.post(`${environment.apiUrl}/rackTemplates`, rack, {
      observe: 'response',
    });
  }

  RACKS_PutRackTemplate(rack: Rack) {
    return this.http.put(
      `${environment.apiUrl}/rackTemplates/${rack.id}`,
      rack,
      {
        observe: 'response',
      }
    );
  }

  RACKS_DeleteRackTemplate(rackId: number) {
    return this.http.delete(`${environment.apiUrl}/rackTemplates/${rackId}`, {
      observe: 'response',
    });
  }

  RACKS_PostRackTemplateProduct(rackId: number, product: Product) {
    return this.http.post(
      `${environment.apiUrl}/rackTemplates/${rackId}/products/${product.id}`,
      {},
      { observe: 'response' }
    );
  }

  RACKS_DeleteRackTemplateProduct(rackId: number, productId: number) {
    return this.http.delete(
      `${environment.apiUrl}/rackTemplates/${rackId}/products/${productId}`,
      { observe: 'response' }
    );
  }

  //PRODUCTS

  PRODUCTS_GetProductTemplates() {
    return this.http.get<Product[]>(`${environment.apiUrl}/productTemplates`, {
      observe: 'response',
    });
  }

  PRODUCTS_GetProductTemplate(templateId: number) {
    return this.http.get<Product>(
      `${environment.apiUrl}/productTemplates/${templateId}`,
      {
        observe: 'response',
      }
    );
  }

  PRODUCTS_PostProductTemplate(product: Product) {
    return this.http.post(`${environment.apiUrl}/productTemplates`, product, {
      observe: 'response',
    });
  }

  PRODUCTS_PutProductTemplate(product: Product) {
    return this.http.put(
      `${environment.apiUrl}/productTemplates/${product.id}`,
      product,
      {
        observe: 'response',
      }
    );
  }

  PRODUCTS_DeleteProductTemplate(templateId: number) {
    return this.http.delete(
      `${environment.apiUrl}/productTemplates/${templateId}`,
      {
        observe: 'response',
      }
    );
  }

  //DOCUMENTS

  DOCUMENTS_GetDocuments() {
    return this.http.get<AADocument[]>(`${environment.apiUrl}/documents`, {
      observe: 'response',
    });
  }

  DOCUMENTS_GetDocument(documentId: number) {
    return this.http.get<AADocument>(
      `${environment.apiUrl}/documents/${documentId}`,
      {
        observe: 'response',
      }
    );
  }

  DOCUMENTS_PostDocument(document: FormData) {
    return this.http.post(`${environment.apiUrl}/documents`, document, {
      observe: 'response',
    });
  }

  DOCUMENTS_PutDocument(document: AADocument) {
    return this.http.put(
      `${environment.apiUrl}/documents/${document.id}`,
      document,
      {
        observe: 'response',
      }
    );
  }

  DOCUMENTS_DeleteDocument(documentId: Number) {
    return this.http.delete(`${environment.apiUrl}/documents/${documentId}`, {
      observe: 'response',
    });
  }
}
