<app-content-wrapper [title]="'title.addDocument' | translate">
    <button mat-icon-button toolbar (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
    <form class="aa-form" content [formGroup]="addDocumentForm">
        <p>
            <button mat-raised-button color="secondary"
                (click)="fileInput.click()">{{fileInput.files!.length <= 0 ? ('form.selectFile' | translate) : fileInput.files![0].name}}</button>
            <input style="visibility:hidden" formControlName="file" type='file' accept="application/pdf" #fileInput />
        </p>
        <p>
            <mat-form-field floatLabel="always" appearance="outline">
                <mat-label>{{'form.name' | translate}}</mat-label>
                <input matInput type="text" formControlName="name" />
            </mat-form-field>
        </p>
        <p>
            <mat-form-field floatLabel="always" appearance="outline">
                <mat-label>{{'form.type' | translate}}</mat-label>
                <mat-select formControlName="type">
                    @for (item of documentTypes; track item) {
                    <mat-option [value]="item">{{'form.documentTypes.'+item | translate}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </p>
        <button mat-raised-button color="primary" [disabled]="addDocumentForm.invalid || uploading" (click)="save()">

            <mat-icon [class]="uploading ? 'icon-rotate' : ''">{{uploading ? 'autorenew' : 'upload'}}</mat-icon>
            {{(uploading ? 'form.uploading': 'form.upload') | translate}}
        </button>
    </form>
</app-content-wrapper>
