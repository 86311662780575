import {
  Component,
  ElementRef,
  Inject,
  LOCALE_ID,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  ReactiveFormsModule,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ContentWrapperComponent } from '../../../components/content-wrapper/content-wrapper.component';
import { ApiService } from '../../../services/api.service';
import { MiscService } from '../../../services/misc.service';
import { Location } from '../../../models/location';
import { Product } from '../../../models/product';
import { ConfirmComponent } from '../../../components/confirm/confirm.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import 'moment/locale/de';
import moment from 'moment';
import { AADocument } from '../../../models/document';

@Component({
  selector: 'app-product',
  standalone: true,
  imports: [
    CommonModule,
    ContentWrapperComponent,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatMomentDateModule,
    MatDatepickerModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  templateUrl: './product.component.html',
  styleUrl: './product.component.scss',
})
export class ProductComponent {
  isBusy = true;
  documents: AADocument[] = [];
  productForm: UntypedFormGroup = this.fb.group({
    id: -1,
    productCode: ['', [Validators.required, Validators.maxLength(50)]],
    name: ['', [Validators.required, Validators.maxLength(50)]],
    intervalA: [
      '',
      [Validators.required, Validators.min(1), Validators.pattern('[0-9]+')],
    ],
    intervalB: [
      '',
      [Validators.required, Validators.min(1), Validators.pattern('[0-9]+')],
    ],
    serialName: ['', Validators.maxLength(50)],
    positionNumber: ['', Validators.maxLength(50)],
    installationDate: '',
  });
  displayedColumns: string[] = ['name', 'typ', 'actions'];
  activatedRow: any;

  constructor(
    public dialogRef: MatDialogRef<ProductComponent>,
    public fb: FormBuilder,
    public dialog: MatDialog,
    private api: ApiService,
    private misc: MiscService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      productId?: number;
      location: Location;
      mode: 'locations' | 'racks' | 'templates';
    }
  ) {}

  async ngOnInit() {
    //retrieve the product or the product template first
    if (this.data.productId != null) {
      const _productId = this.data.productId;

      if (this.data.mode == 'locations') {
        this.api
          .LOCATIONS_GetProduct(this.data.location.id, _productId)
          .subscribe({
            next: (res) => {
              if (res.ok && res.body) {
                this.productForm.patchValue(res.body);

                if (res.body.documents) {
                  this.documents = res.body.documents;
                }
                this.isBusy = false;
              } else {
                this.misc.showError(this.translate.instant('error.general'));
              }
            },
            error: (err) => {
              this.misc.showError(this.translate.instant('error.general'));
            },
          });
      } else {
        this.api.PRODUCTS_GetProductTemplate(_productId).subscribe({
          next: (res) => {
            if (res.ok && res.body) {
              this.productForm.patchValue(res.body);
              if (res.body.documents) {
                this.documents = res.body.documents;
              }
              this.isBusy = false;
            } else {
              this.misc.showError(this.translate.instant('error.general'));
            }
          },
          error: (err) => {
            this.misc.showError(this.translate.instant('error.general'));
          },
        });
      }
    } else {
      this.isBusy = false;
    }
  }

  viewDocument(data: AADocument) {
    if (!data.url) return;
    window.open(data.url, '_blank');
  }

  deleteDocument(data: AADocument) {
    if (!data.id) return;

    this.dialog
      .open(ConfirmComponent, {
        data: {
          title: this.translate.instant('form.deleteDocument'),
          message: this.translate.instant('form.deleteDocumentInfo'),
        },
      })
      .afterClosed()
      .subscribe((accepted) => {
        if (!accepted) return;
        this.api.DOCUMENTS_DeleteDocument(data.id!).subscribe({
          next: (res) => {
            if (res.ok) {
              this.misc.showInfo(
                this.translate.instant('success.deleteDocument', {
                  document: data.name,
                })
              );
              this.ngOnInit();
            } else {
              this.misc.showError(this.translate.instant('error.general'));
            }
          },
          error: (err) => {
            this.misc.showError(this.translate.instant('error.general'));
          },
        });
      });
  }

  openAddDocumentDialog() {
    const dialogRef = this.dialog.open(AddDocumentDialog, {
      data: {
        productId: this.data.productId,
      },
      maxHeight: '80vh',
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res?.reload) {
        this.ngOnInit();
      }
    });
  }

  save() {
    if (this.data.mode == 'locations') {
      //EDIT LOCATION PRODUCT SERIALNAME

      //fix UTC date issue
      const _formData = this.productForm.value;
      console.log('Before: ', _formData);
      //const _installationDate = moment(_formData.installationDate).format("YYYY-MM-DD");
      if (_formData.installationDate != '') {
        const _installationDate = new Date(_formData.installationDate)
          .toISOString()
          .split('T')[0];
        if (new Date(_installationDate).getTime() == 0) {
          _formData.installationDate = '';
        } else {
          _formData.installationDate = _installationDate;
        }
      }
      console.log('After: ', _formData);
      this.api
        .LOCATIONS_PatchProduct(this.data.location.id, _formData as Product)
        .subscribe({
          next: (res) => {
            if (res.ok) {
              this.dialogRef.close({ reload: true });
              this.misc.showInfo(this.translate.instant('success.save'));
            } else {
              this.misc.showError(this.translate.instant('error.general'));
            }
          },
          error: (err) => {
            this.misc.showError(this.translate.instant('error.general'));
          },
        });
    } else if (this.data.productId) {
      //EDIT EXISTING PRODUCT TEMPLATE
      this.api
        .PRODUCTS_PutProductTemplate(this.productForm.value as Product)
        .subscribe({
          next: (res) => {
            if (res.ok) {
              this.dialogRef.close({ reload: true });
              this.misc.showInfo(this.translate.instant('success.save'));
            } else {
              this.misc.showError(this.translate.instant('error.general'));
            }
          },
          error: () => {
            this.misc.showError(this.translate.instant('error.general'));
          },
        });
    } else {
      //CREATE NEW PRODUCT TEMPLATE
      this.api
        .PRODUCTS_PostProductTemplate(this.productForm.value as Product)
        .subscribe({
          next: (res) => {
            if (res.ok) {
              this.dialogRef.close({ reload: true });
              this.misc.showInfo(
                this.translate.instant('success.addProduct', {
                  product: this.productForm.get('name')?.value,
                })
              );
            } else {
              this.misc.showError(this.translate.instant('error.general'));
            }
          },
          error: () => {
            this.misc.showError(this.translate.instant('error.general'));
          },
        });
    }
  }

  deleteProduct() {
    if (!this.data.productId) return;
    this.dialog
      .open(ConfirmComponent, {
        data: {
          title: this.translate.instant('form.deleteProduct'),
          message: this.translate.instant('form.deleteProductInfo'),
        },
      })
      .afterClosed()
      .subscribe((accepted) => {
        if (accepted) {
          this.api
            .PRODUCTS_DeleteProductTemplate(this.data!.productId!)
            .subscribe({
              next: (res) => {
                if (res.ok) {
                  this.dialogRef.close({ reload: true });
                  this.misc.showInfo(
                    this.translate.instant('success.deleteProduct', {
                      product: this.productForm.get('name')?.value,
                    })
                  );
                } else {
                  this.misc.showError(this.translate.instant('error.general'));
                }
              },
              error: () => {
                this.misc.showError(this.translate.instant('error.general'));
              },
            });
        }
      });
  }
}

@Component({
  selector: 'add-document-dialog',
  templateUrl: 'add-document-dialog.html',
  styleUrl: './product.component.scss',
  standalone: true,
  imports: [
    ContentWrapperComponent,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatSelectModule,
    MatIconModule,
    ReactiveFormsModule,
  ],
})
export class AddDocumentDialog {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  documentTypes = ['drawing', 'partslist', 'report'];
  documents: AADocument[] = [];
  addDocumentForm = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(200)]],
    type: ['', Validators.required],
    file: ['', Validators.required],
  });

  uploading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddDocumentDialog>,
    public fb: FormBuilder,
    private api: ApiService,
    private misc: MiscService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  save() {
    if (this.addDocumentForm.invalid) return;

    this.uploading = true;

    const _form = new FormData();
    _form.append('name', this.addDocumentForm.get('name')?.value ?? '');
    _form.append('type', this.addDocumentForm.get('type')?.value ?? '');
    _form.append('file', this.fileInput.nativeElement.files![0] ?? '');

    if (this.data.productId != null) {
      _form.append('productTemplateId', this.data.productId);
    }
    if (this.data.rackId != null) {
      _form.append('rackTemplateId', this.data.rackId);
    }

    this.api.DOCUMENTS_PostDocument(_form).subscribe({
      next: (res) => {
        if (res.ok) {
          this.misc.showInfo(
            this.translate.instant('success.addDocument', {
              document: this.addDocumentForm.get('name')?.value,
            })
          );
          this.uploading = false;
          this.dialogRef.close({ reload: true });
        } else {
          this.misc.showError(this.translate.instant('error.general'));
        }
      },
      error: (err) => {
        this.misc.showError(this.translate.instant('error.general'));
      },
    });
  }
}
