<mat-card>
    <mat-card-header>
        <mat-card-title>{{data.title}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        {{data.message}}
    </mat-card-content>
    <mat-card-actions align="end" class="card-toolbar">
        <button mat-raised-button (click)="deny()">{{data?.no ?? ('form.no' | translate)}}</button>
        <button mat-raised-button (click)="accept()" color="primary">{{data?.yes ?? ('form.yes' | translate)}}</button>
    </mat-card-actions>
</mat-card>
