import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class MiscService {
  constructor(private snackBar: MatSnackBar) {}

  showInfo(message: string, buttonText: string = '', duration: number = 4000) {
    this.snackBar.open(message, buttonText, {
      panelClass: ['aa-snackbar', 'info', buttonText == '' ? 'no-button' : ''],
      duration,
    });
  }

  showError(message: string, buttonText: string = '', duration: number = 4000) {
    this.snackBar.open(message, buttonText, {
      panelClass: ['aa-snackbar', 'error', buttonText == '' ? 'no-button' : ''],
      duration,
    });
  }
}
