@defer(when !isBusy) {
<app-content-wrapper
    [title]="data?.productId ? ('title.product' | translate: { product: productForm.get('name')?.value }) : 'title.addProduct' | translate">
    <button mat-icon-button toolbar (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
    <div content>
        <form class="aa-form" [formGroup]="productForm">
            @if(data?.mode != "locations") {
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.productId' | translate}}</mat-label>
                    <input formControlName="productCode" matInput maxlength="50" [readonly]="data?.location != null">
                </mat-form-field>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.name' | translate}}</mat-label>
                    <input formControlName="name" matInput maxlength="50" [readonly]="data?.location != null">
                </mat-form-field>
            </p>
            }
            @if (data?.mode == 'locations') {
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.positionNumber' | translate}}</mat-label>
                    <input formControlName="positionNumber" maxlength="50" matInput>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.tagNumber' | translate}}</mat-label>
                    <input formControlName="serialName" maxlength="50" matInput>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.installationDate' | translate}}</mat-label>
                    <input  matInput formControlName="installationDate" [matDatepicker]="picker" (click)="picker.open()">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </p>
            }
            @if(data?.mode != "locations") {
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.intervalA' | translate}}</mat-label>
                    <input type="number" formControlName="intervalA" maxlength="50" matInput>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'form.intervalB' | translate}}</mat-label>
                    <input type="number" formControlName="intervalB" maxlength="50" matInput>
                </mat-form-field>
            </p>
            @if (data?.productId != null) {
            <app-content-wrapper [title]="'title.documents' | translate" headerColor="#444444" [padding]="false">
                @if (data.mode == 'templates') {
                <button mat-icon-button toolbar (click)="openAddDocumentDialog()"><mat-icon>add</mat-icon></button>
                }
                <table content mat-table [dataSource]="documents" class="example-table" matSort matSortActive="created" matSortDisableClear
                    matSortDirection="desc" #documentsTable content>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'form.name' | translate}}</th>
                        <td mat-cell *matCellDef="let row">{{row.name}}</td>
                    </ng-container>

                    <ng-container matColumnDef="typ">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'form.type' | translate}}</th>
                        <td mat-cell *matCellDef="let row">{{'form.documentTypes.'+row.type | translate}}</td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'form.actions' | translate}}</th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button (click)="viewDocument(row)">
                                <mat-icon>visibility</mat-icon>
                            </button>
                            <button mat-icon-button (click)="deleteDocument(row)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd;"
                        [ngClass]="[odd ? 'odd-row' : '', activatedRow == row ? 'activated-row' : '']" (click)="activatedRow = row"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                            <div class="table-empty-row">
                                {{'form.emptyData' | translate}}
                            </div>
                        </td>
                    </tr>
                </table>
            </app-content-wrapper>
            }
            }
            <button mat-raised-button color="primary" (click)="save()" [disabled]="productForm.invalid || productForm.pristine">
                <mat-icon>save</mat-icon>
                {{'form.save' | translate}}
            </button>
            @if (data.mode == 'templates' && data.productId) {
            <br /><br />
            <button mat-raised-button (click)="deleteProduct()">
                <mat-icon>delete</mat-icon>
                {{'form.deleteProduct' | translate}}
            </button>
            }
        </form>
    </div>
</app-content-wrapper>
}
@placeholder {
<mat-progress-bar mode="indeterminate" style="width: 20vw;"></mat-progress-bar>
}
